// extracted by mini-css-extract-plugin
export var colourRow = "styles-module--colourRow--c2778";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var row2 = "styles-module--row2--60747";
export var row3 = "styles-module--row3--4c2fd";
export var row4 = "styles-module--row4--16efb";
export var row5 = "styles-module--row5--0b3fd";
export var row6 = "styles-module--row6--81f2f";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";